function initContactForm() {

    $('.contact-form').validate({
        submitHandler: function() {
            var data = $('.contact-form').serialize();
            var object = {
                'success': function (response){
                    $('.contact-form .form-response').removeClass('alert-success');
                    $('.contact-form .form-response').removeClass('alert-danger');

                    if(response['status']=='ok'){
                        $('.contact-form .form-response').addClass('alert-success').show().html(response['message']);
                    }else{
                        $('.contact-form .form-response').addClass('alert-danger').show().html(response['error']);
                    }
                },
                'data' : data
            };
            $.ajax(object);
            return false;
        },
        errorPlacement: function(error, element) {
            // error.hide();
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
             $(element).closest('.form-group').append('<span class=" form-control-feedback"><i class="fa fa-exclamation-triangle"></i></span>');
             $(element).parent().addClass('has-error has-feedback');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
             $(element).parent().removeClass('has-error has-feedback');
        }
    });
}

function loadContactMap(){
    if(typeof(google)!=='undefined'){
        var myLatlng = new google.maps.LatLng(56.6373083,-2.9118989);
        var options = {
            zoom:17,
            center: myLatlng,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        var map = new google.maps.Map($('#map-container-contact')[0], options);

        var image = {
            url: SKIN  + 'img/marker.png',
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(12, 59)
        };
        var marker = new google.maps.Marker({
            position: myLatlng,
            map: map
        });
    }
}

function initCompaniesMap(){
    $('header').addClass('force-bg');
    var resizeTime = 100; // total duration of the resize effect, 0 is instant
    var resizeDelay = 100; // time to wait before checking the window size again
    setTimeout(function(){
        $('#fig').mapster({
            clickNavigate: true,
            fillColor: 'ffffff',
            fillOpacity: 0.5
        });
    },500);



    // Resize the map to fit within the boundaries provided

    function initTarget() {
        $("#figMap area").on('click', function (e) {
            var link = $(this).data('link');
            window.open(link, '_blank');
        });
    }
    function resize(maxWidth, maxHeight) {
        var image = $('#fig'),
            imgWidth = image.width(),
            imgHeight = image.height(),
            newWidth = 0,
            newHeight = 0;

        if (imgWidth / maxWidth > imgHeight / maxHeight) {
            newWidth = maxWidth;
        } else {
            newHeight = maxHeight;
        }
        image.mapster('resize', newWidth, newHeight, resizeTime);
    }

    // Track window resizing events, but only actually call the map resize when the
    // window isn't being resized any more
    function onWindowResize() {
        var curWidth = $("#company_map_container").width(),
            curHeight = $("#company_map_container").height(),
            checking = false;
        if (checking) {
            return;
        }
        checking = true;
        window.setTimeout(function() {
            var newWidth = $("#company_map_container").width(),
                newHeight = $("#company_map_container").height();
            if (newWidth === curWidth && newHeight === curHeight) {
                resize(newWidth, newHeight);
            }
            checking = false;
        }, resizeDelay);
    }
    $(window).bind('resize', onWindowResize);
    $(document).ready(function() {
        onWindowResize();
    });
}
