var xhr, scheduled = false, _throttleDelay = 200, $body, $modal, $secondaryModal,$window;
var timeout = false;
var delta = 200;
var subnavOrigin = 0;
var body = document.querySelector("body");

$(function(){	
	"use strict";
	$(".megamenu_mobile").mCustomScrollbar({
		theme:"dark"
	});
	$('#menu_cover').click(function(){
		//console.log(13214321);
		$('.burger-menu').trigger('click');
	});

	

	
	$('.atcb-link').click(function() {
		
		if($(this).parent().hasClass('open')){
			$(this).parent().removeClass('open');
		}else{
			$('.addtocalendar').removeClass('open');
			$(this).parent().addClass('open');
		}
		
		return false;
	});
	
	

	$('.megamenu_mobile .toggle').click(function(){
		var _this = $(this);					
		if(_this.parent().hasClass('open')){
			$('.megamenu_mobile .with_submenu.open').removeClass('open');	
		}else{
			$('.megamenu_mobile .with_submenu.open').removeClass('open');
			_this.parent().addClass('open');	
		}

		return false;
	});
	var tag = document.createElement('script');
	
	var waypoints = $('.way-point-animate').waypoint(function(direction) {
		$(this.element).addClass('shown');
	}, {
		offset: '95%'
	});
	var waypoints = $('.way-point-history').waypoint(function(direction) {
		$(this.element).removeClass('is-hidden');
		$(this.element).addClass('bounce-in');
	}, {
		offset: '95%'
	});
	
	
	$('.sub-nav .dropdown-menu').css({'min-width':$('.sub-nav .dropdown-toggle').innerWidth()});
	
	$('.sub-nav a.menu').click(function(){
		$('body.menu-open').removeClass('menu-open');
	});
	
	
	$('.markets .full-box-link').on('mouseenter',function() { 
		$(this).parent().parent().addClass('hover');
	});
	$('.markets .full-box-link').on('mouseleave',function() { 
		if($(this).parent().parent().hasClass('hover')){
			$(this).parent().parent().removeClass('hover');
		}
	});
	
	$('.stats .full-box-link').on('mouseenter',function() { 
		$(this).parent().addClass('hover');
	});
	
	$('.stats .full-box-link').on('mouseleave',function() { 
		if($(this).parent().hasClass('hover')){
			$(this).parent().removeClass('hover');
		}
	});
	
	callbacks();
	if($(window).scrollTop() > 30) {
		$(".site-header.show-cont").removeClass("show-cont");
		$(".fixed-nav").addClass("show-fix");		
	}
	if($('.sub-nav').length > 0){
        subnavOrigin = $('.sub-nav').offset().top;
		$(window).resize(function(){
			subnavOrigin = $('.sub-nav').offset().top;	
		});
    }

	if(!$('.sub-nav').hasClass('fixed') && $(window).scrollTop() > subnavOrigin-75){
		 $(".sub-nav").addClass("fixed");
	}
});

$(document).on('scroll',function() { 
	'use strict';
    var sub_nav = $('.sub-nav');
	if($(window).scrollTop() > 30) {
		 $(".fixed-nav").addClass("show-fix");		
		 $(".site-header.show-cont").removeClass("show-cont");
	}else{
		$(".fixed-nav").removeClass("show-fix");		
		$(".site-header").addClass("show-cont");
	}
	if(!sub_nav.hasClass('fixed') && $(window).scrollTop() > subnavOrigin-75){
        sub_nav.addClass("fixed");
	}else if(sub_nav.hasClass('fixed') && $(window).scrollTop() < subnavOrigin-75){
        sub_nav.removeClass("fixed");
	}
});

function featuredCarousel(){	
	"use strict";
	$('.related-carousel').slick({
		 slidesToShow: 1,
		 slidesToScroll: 1,
		 dots: true,
		 arrows: false
	});
		
	$('.featured-carousel').slick({
		 slidesToShow: 1,
		 slidesToScroll: 1,
		 dots: true,
		 arrows: false
	});
}
function initCarousel(){	
	"use strict";
	$('.carousel-list').slick({
		 slidesToShow: 1,
		 slidesToScroll: 1,
		 dots: true,
		 arrows: false
	});
		
}

function initRoofshieldCarousel(){	
	"use strict";
	$('.roofshield-carousel-list').slick({
		 slidesToShow: 1,
		 slidesToScroll: 1,
		 autoplay: true,
  		 autoplaySpeed: 4000,
  		 infinite: true,
		 dots: false,
		 arrows: false,
		 speed: 500,
  	     fade: true,
 		 cssEase: 'linear',
 		 swipeToSlide:false,
 		 swipe:false,
 		 touchMove:false,
 		 pauseOnHover:false

	});
		
}


function updateCalendar(){	
	"use strict";
	$('.event-item').each(function(){
		var _this = $(this);
		var date = _this.data('date');
		$('#calendar .fc-day-top[data-date="' + date + '"]').addClass("fc-event");				
	});
	$('#calendar .fc-day-top[data-date="' + $('.event-item.show-event').data('date') + '"]').addClass("active");
	$('.fc-event .fc-day-number').click(function(){
		$('.fc-event').removeClass('active');
		$(this).parent().addClass('active');
		var date = $(this).parent().data('date');
		if(!$('.event-item[data-date="' + date + '"]').hasClass('show-event')){
			$('.event-item.show-event').removeClass('show-event');
			$('.event-item[data-date="' + date + '"]').addClass('show-event');	
		}
	});
}

function initNewsFilter(){
	"use strict";
	$('.news-category a.active').tab('show');
	$('.news-category a').click(function (e) {
	  e.preventDefault();
	  $('.news-category a').removeClass('active');
		$(this).addClass('active');
	  $(this).tab('show');
	});
}

function initDownloadsFilter(){
	"use strict";
	$('.donwload-category a.active').tab('show');
	$('.donwload-category a').click(function (e) {
	  e.preventDefault();
	  $('.donwload-category a').removeClass('active');
		$(this).addClass('active');
	  $(this).tab('show');
	});
	var scroller = $(".donwloads-filter").mCustomScrollbar({
		axis:"x",
		advanced:{
			autoExpandHorizontalScroll:true
		}
	});
	$('.scroll-controls .left').click(function(){
		scroller.mCustomScrollbar("scrollTo",'left');
		return false;
	});
	$('.scroll-controls .right').click(function(){
		scroller.mCustomScrollbar("scrollTo",'right');
		return false;
	});
	
	
	$(window).resize(function(){
		checkForScrollbar();
	});
	setTimeout(function(){
		checkForScrollbar();	
	},200);
}
function checkForScrollbar(){
	"use strict";
	if($('.donwloads-filter .mCSB_container').hasClass('mCS_no_scrollbar_x')){
		$('.scroll-controls').addClass('hidden');
	}else{
		$('.scroll-controls').removeClass('hidden');
	}	
}

function initCollapse(){
	"use strict";
	$('.collapse.control').click(function () {
		$('.nav.nav-tabs').toggleClass('hide');
		$('.scroll-controls').toggleClass('hide');
		var link = $(this).data('link');
		if( $(link).hasClass('in') ){
			$(this).removeClass('expanded');
			$(link).removeClass('in');
		}else{
			$(this).addClass('expanded');
			$(link).addClass('in');
		}
		return false;
	});
}
function initNumberAnimations(){	
	"use strict";
	var waypoints = $('.numAnimate').waypoint(function(direction) {
		$(this.element).addClass('shown');
		$('.numAnimate.shown').each(function(){
			if(!$(this).hasClass('animated')){
				$(this).prop('Counter',0).animate({
				Counter: $(this).text()
			}, {
				duration: 4000,
				easing: 'swing',
				step: function (now) {
					$(this).text(Math.ceil(now));
				}
    		});
				
				$(this).addClass('animated');
			}
		});		
	}, {
		offset: '90%'
	});
	
}
function initMorphsearch(){
	"use strict";

 	$('#top_nav_search_form').validate({
        rules: {
            'search-param': 'required'
        },
        messages: {
            'search-param': ''
        },
        submitHandler: function () {
            $('.morphsearch-content .search-results').html('<img style="margin: 10px auto;text-align:center;display:block" src="'+SKIN+'img/bx_loader.gif" />');
            $.ajax({data: $('#top_nav_search_form').serialize(), dataType: 'html', success: function (data) {
                $('.morphsearch-content .search-results').html(data);
            }});
        }
    });
	var morphSearch = $('#morphsearch'),
		input = $('.search-button'),
		ctrlClose = $('span.morphsearch-close'),
		isOpen = false,
		toggleSearch = function () {

			if (isOpen) {
				morphSearch.removeClass('open');
				
				$('.morphsearch-content .search-results').empty();
				$('.morphsearch-input').val('');
				//classie.remove( morphSearch, 'open' );

				// trick to hide input text once the search overlay closes
				// todo: hardcoded times, should be done after transition ends

				if (input.value !== '') {
					setTimeout(function () {
						morphSearch.addClass('hideInput');
						setTimeout(function () {
							morphSearch.removeClass('hideInput');
							input.value = '';
						}, 300);
					}, 500);
				}

				input.blur();
			}
			else {
				morphSearch.addClass('open');
			}
			isOpen = !isOpen;
			return false;
		};

	input.on('click', toggleSearch);
	ctrlClose.on('click', toggleSearch);

	$(document).on('keydown', function (ev) {
		var keyCode = ev.keyCode || ev.which;
		if (keyCode === 27 && isOpen) {
			toggleSearch(ev);
		}
	});	
}

$(document).ready(function () {
	"use strict";
    $(document).on('click', 'a.filter', function (e) {
    
        var key = $(this).data('filterName'),
            target = $(this).data('target'),
            value = $(this).data('value'),
            label = $(this).text(),
            multiple = $(this).data('multiple');

        if($(this).closest('li').hasClass('active') || $(this).hasClass('active')){
            return false;
        }

        if (multiple == true) {

            if ($(this).parent().hasClass('active')) {
                // it's already checked. Uncheck it please
                $(this).parent().removeClass('active');
                $('form[name="' + target + '-nav"] input[name="' + key + '[]"][value="' + value + '"]').remove();

            } else if (value == '') {
                $('a.filter[data-filter-name="' + key + '"]').parent().removeClass('active');
                $('form[name="' + target + '-nav"] input[name="' + key + '[]"]').remove();
            } else {
                // Not checked
                $(this).parent().addClass('active');
                $('form[name="' + target + '-nav"]').append('<input type="hidden" name="' + key + '[]" value="' + value + '"/>');
            }
        } else {
            $('.filter[data-filter-name="' + key + '"]').parent().removeClass('active');
            $(this).parent().addClass('active');
            var t = $(this).closest('.filter-group').find('.current-filter-value');
            if(value!==''){
                t.html(label);
            }else if(value===''){
                t.html(t.data('default'));
            }

            $('form[name="' + target + '-nav"] input[name="' + key + '"]').val(value);
        }


        filter(target);

        e.preventDefault();
    });



	$('.search-close').each(function(){
		$(this).click(function(){
			console.log($(this).data('id'));
			$("#"+$(this).data('id')).val('');
		});	
	});	
	

	if ("ontouchstart" in window || navigator.msMaxTouchPoints) {
		$('body').addClass('touch');
	} else {
		$('body').addClass('no-touch');		
	}

	
    var keydown_timeout;
    $body = $('body');
    $window = $(window);

    $body.append('<div id="modalSecondary" class="modal fade"><div class="modal-content"></div></div>');
    $body.append('<div id="modal" class="modal fade"><div class="modal-content"></div></div>');

    $modal = $('#modal');
    $secondaryModal = $('#modalSecondary');

    $(document).on('ifToggled','.toggle-select-all', function(event){
        var target = $(this).data('toggleSelectAllTarget');
        $(target).each(function () {
            $(this).iCheck('toggle');
        });
    });

  

    $(document).on("click", ".load-more", function () {
        var target = $(this).data('target');
        more(target);
        return false;
    });

    $(document).on('click','a[data-target="#search-collapsible"]',function(){
        registerGAEvent('button','click','search');
        scroll2position(0);
        setTimeout(function(){
            $('input[name="search_param"]').focus();
        },500);
    });



      $(document).on('click', '.scroll-to-element', function (e) {
        scroll2element($(this).attr('href'));
        e.preventDefault();
    });

    $(document).on('click', '.close-collapsible', function () {
        $(this).closest('.in').find('input:text').val('');
        $(this).closest('.in').collapse('hide');
        return false;
    });


    $(document).on('click', '.collapse-all', function () {
        $('.panel-collapse').collapse('hide');
        $(this).toggle();
        $('.expand-all').toggle();
        return false;
    });

    $(document).on('click', '.expand-all', function () {
        $(this).toggle();
        $('.collapse-all').toggle();
        $('.panel-collapse').collapse('show');
        return false;
    });

    
   

    $(document).on('click', ".ajax-modal", function (e) {
		
        try{
            if(typeof ($(this).attr("href"))!=="undefined"){
				openAjaxModal($(this).attr('href'), $('#'+ $(this).data('modal')));				
			}else{
				openAjaxModal($(this).data('href'), $('#'+ $(this).data('modal')));
			}
			e.preventDefault();
        }catch(Error){
            console.log(Error);
        }

        return false;
    });

   

    $(document).on('click', '[data-action="submit"]', function () {
        var target = $(this).data('target');
        $(target).submit();
        return false;
    });
    $(document).on('click', '.go-to-top', function () {
        scroll2position(0);
        return false;
    });

    linesAnimations();
   
});
/*
var timeout = false;
var rtime;
var delta = 200;
var target = '.mpla .mpla .mpla';

equalizeHeight ();
$(window).resize(function(){
    var rtime = new Date();
    if (timeout === false) {
        timeout = true;
        resizedeltaFunction ;

    }
});

function resizedeltaFunction(target){
    if (new Date() - rtime < delta) {
        setTimeout(resizedeltaFunction, delta);
    } else {
        timeout = false;
        equalizeHeight ();
    }
}
function equalizeHeight(target,offset) {
    $(target).each(function(){
        $(target).css('min-height','auto');
    });
    var min_height = 0;
    setTimeout(function(){
        $(target).each(function(){
            if($(this).innerHeight() > min_height){
                min_height = $(this).innerHeight();
            }
        });
        if(min_height>0){
            $(target).css('min-height',min_height);
        }
    },500);
}*/

function openAjaxModal(href,$mm){

     $m = $mm || $modal;

    $m.removeData('bs.modal');
    $m.empty();
    $m.load(href,function(){
        $m.modal('show');
        //skinFormElements('#'+$m.attr('id'));
        callbacks('#'+$m.attr('id'));
    });

}

function equalizeHeight(target){
	$('.'+ target).each(function(){
		$('.'+ target).addClass('autoHeight');
		$('.'+ target).css('min-height','auto');
		if($('.'+ target).parent().hasClass('card')){
			$('.'+ target).css('height','auto');
		}
	});
	
    var min_height = 0;
    setTimeout(function(){
        $('.'+ target).each(function(){
            if($(this).innerHeight() > min_height){
                min_height = $(this).innerHeight();
            }
        });
        if(min_height>0){
            $('.'+ target).css('min-height',min_height);
			if($('.'+ target).parent().hasClass('card')){
				$('.'+ target).css('height',min_height);
			}
        }
		$('.'+ target).each(function(){			 
			$('.'+ target).removeClass('autoHeight');
		});
		
    },500);
	
}


function form_submit_response(target, type, message, r) {
    var redirect = r || false;

    var new_type = 'notice';
    var icon = 'fa fa-check';
    var time = 5000;
    if(type!='success'){
        new_type = type;
        icon='fa fa-warning'
        time = 20000;
    }

    $('#toast-container').empty();


    var $toastContent = $('<p><i class="icon '+icon+' fa-2x"></i> '+message+'</p>');
    Materialize.toast($toastContent, time);

    if (redirect) {
        setTimeout(function () {
            window.location.reload();
        }, 2000);
    }
}

function triggerClick(target) {
	$('.'+target).trigger('click');
}

function callbacks(c) {
    var container = c || '';
    ajaxContent(container + ' .ajax-content');

    $(container + ' .callbacks').each(function () {
        if(!$(this).hasClass('callbacks-executed')){
            if(container !=='' || !$(this).hasClass('callbacks-executed')){
            var c = $(this).data();
            for (var i in c) {
                if (i.indexOf('fn') == 0 && c[i] != '') {
                    var fn = c[i];
                    var params = c[ 'params' + i ] || null;

                    $(this).addClass('callbacks-executed');

                    try {
                        if (c[ 'after' + i ] && typeof(window[c[ 'after' + i ]]) === 'function') {

                            var callbackAfter = c[ 'after' + i ];
                            var callbackAfterParams = c[ 'after' + i + 'Params' ] || null;

                            window[ fn ](params, function(){
                                window[callbackAfter](callbackAfterParams);
                            });
                        } else {
                            window[ fn ](params);
                        }
                    } catch (Error) {
                        console.log(Error + ' '+fn);
                    }
                }
            }
        }
        }
    });
}

function ScrollHandler(e) {
    if (!scheduled) {
        scheduled = true;
        setTimeout(function () {
            if ($(window).scrollTop() + $(window).height() > $(document).height() - 300) {
                more(e.data.target);
            }
            scheduled = false;
        }, _throttleDelay);
    }
}


function scroll2position(pos,t) {
    $('html, body').animate({scrollTop: pos}, t || 500);
}


function more(target) {
    if ($('form[name="' + target + '-nav"] ').hasClass('busy')) {
        return false;
    }

    var c = $('form[name="' + target + '-nav"] input[name="page"]').val();
    var offset = $('form[name="' + target + '-nav"] input[name="offset"]').val();
    $('form[name="' + target + '-nav"] input[name="page"]').val(Number(c) + 1);
    $('form[name="' + target + '-nav"]').addClass('busy');
    $('.load-more[data-target="'+target+'"]').hide();
    $.ajax({data: $('form[name="' + target + '-nav"]').serialize(), dataType: 'html', success: function (result) {
        $('form[name="' + target + '-nav"]').removeClass('busy');

        var $result = $('<div>' + result + '</div>');

        var num_items = $result.find('.item').length;
        if ( num_items > 0) {
            $result.find('.item').hide();
            $('.' + target + '-list').append($result.html());
            $('.' + target + '-list .item').fadeIn('fast');

            callbacks('.' + target + '-list');

            $('.load-more[data-target="'+target+'"]').show();
            if(num_items<offset){
                $(document).off('scroll', ScrollHandler);
            }
        } else {
            $('.load-more[data-target="'+target+'"]').hide();
            $(document).off('scroll', ScrollHandler);
        }

        $('.' + target + '-list .way-point-animate').waypoint(function(direction) {
            $(this.element).addClass('shown');
        }, {
            offset: '95%'
        });

        if($('.' + target + '-list').data('afterFilter')){
            window[$('.' + target + '-list').data('afterFilter')]($('.' + target + '-list').data('afterFilterParams'));
        }
    }
    });
}

function linesAnimations(){
    var linee_tl = new TimelineLite({paused:true});
    var prod_enter_tl = new TimelineLite({paused:true});
    if($('.full_height').length > 0){
        resize();
    }
    $(".wpcf7 [type='submit']").on('click', function(){
        var stop = 0;
        var timer_wpcf7 = setInterval(function(){
            if($('.wpcf7-response-output').css('display') != 'none' && stop == 0){
                stop = 1;
                setTimeout(function(){
                    $('.wpcf7-response-output').slideUp();
                    stop = 0;
                    clearInterval(timer_wpcf7);
                }, 4000);
            }
        },500);
        setTimeout(function(){
            if($('.wpcf7-response-output').css('display') != 'none'){
                $('.wpcf7-response-output').slideUp();
            }
        }, 4000);
    });
    $(".scroll_slide").on('click', function(){
        $("html, body").animate({ scrollTop: $(window).height() }, 800);
    });

    function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }

    var animation_from_hidden = $('[data-module="loader"][data-type="hidden"]').length > 0;

    TweenLite.set('.linee_bck span', { background: 'rgba(0, 0, 0, .22)',y: '-100.1%'});
    TweenMax.staggerTo($('.linee_bck span').not('[data-module="nav"] .linee_bck span'), 4,{y:'0%',background: 'rgba(0, 0, 0, .05)',ease: Expo.easeInOut, delay: -.5,onComplete: function(){
        TweenLite.set($('.linee_bck span'),{y:'0%',background: 'rgba(0, 0, 0, .05)'});
    }}, .2);
    TweenMax.staggerTo($('.pagination a'),1,{ opacity: 1, delay: 3},.1);

    setTimeout(function(){
        linee_tl.play();
    },500);


}

function initShares(){
    "use strict";
    $('.close-share-this').click(function(){
        $('.modal.in').modal('hide');
        return false;
    });

    $('.clipboardButton').html('<i class="fa fa-copy"></i>  Copy Link');


    var clipboard = new Clipboard('.clipboardButton');
    clipboard.on('success', function(e) {
        console.info('Action:', e.action);
        console.info('Text:', e.text);
        console.info('Trigger:', e.trigger);

        $('.clipboardButton').html('<i class="fa fa-check"></i>  Copied');

        e.clearSelection();
    });
    clipboard.on('error', function(e) {
        console.error('Action:', e.action);
        console.error('Trigger:', e.trigger);

    });
}
var ajax_contents = [];
function ajaxContent(c){
    $modal = $('#modal');
    var contents = c || '.ajax-content';
    $(contents).each(function () {
        ajax_contents.push(
            {
                'target': $(this),
                'content':$(this).data('ajaxContent')
            }
        );
    });
    loadAjaxContent();
}

function loadAjaxContent(){
    if(ajax_contents.length > 0){
        var target  = ajax_contents[0].target,
            content = ajax_contents[0].content;
            
        /*if(target.hasClass('ajax-load-initiated')) {
            ajax_contents.splice(0, 1);
            if(ajax_contents.length > 0){
                loadAjaxContent();
            }
            return false;
        }
*/
        target.addClass('ajax-load-initiated');

        target.load(content,function(){
            var uniq = (Math.random() + '').replace('0.', '');
            target.addClass('ajax-loaded-'+uniq);
            callbacks('.ajax-loaded-'+uniq);
            ajax_contents.splice(0, 1);
            if(ajax_contents.length > 0){
                loadAjaxContent();
            }
        });
    }else{
        $('.ajax-load-initiated').removeClass('ajax-load-initiated');
    }
}

function copyToClipboard(target){
	$('#'+target).on('click', function(){
		$('#'+target+' .copy-text').select();
		var successful = document.execCommand('copy');
		if(successful) {
			Materialize.toast('URL copied to clipboard', 4000);
		} else {
			Materialize.toast('Oops, unable to copy', 4000);
		}
    });
}

function loadMap(){
    $.ajax({
        url: '//maps.googleapis.com/maps/api/js?key=AIzaSyABUQi4EG0T7OEx8qEo9zpmoREbSNEY4hM',
        dataType: "script",
        success:function(){
            if(typeof(google)!=='undefined'){
                var myLatlng = new google.maps.LatLng(56.6373083,-2.9118989);
                var options = {
                    zoom:17,
                    center: myLatlng,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                };
                var map = new google.maps.Map($('#map-container-contact')[0], options);

                var image = {
                    url: SKIN  + 'img/marker.png',
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(12, 59)
                };
                var marker = new google.maps.Marker({
                    position: myLatlng,
                    map: map
                });
            }
        }
    });
}

function filter() {
    var target = arguments[0];
    var after = arguments[1];
    $('form[name="' + target + '-nav"] input[name="page"]').val(0);
    var request_data = $('form[name="' + target + '-nav"]').serializeArray(),
        excludes = ['module', 'action', 'active_for', 'language_id', 'data_type', 'country_code'],
        includes = ['cid[]','sort_term','sort_way'],
        elements = {};

    var initialHeight = $('.' + target + '-list').innerHeight();
    $('.' + target + '-list').height(initialHeight).css({'position':'relative','min-height':100,'overflow':'hidden'}).html('<div class="loading la-animate"></div>');

    $.ajax({data: request_data, dataType: 'html', success: function (data) {
        $('.' + target + '-list').css({'overflow':'visible'});
        $('.' + target + '-list').html(data).height('auto');



        var num_items = $('.' + target + '-list .item').length;
        var offset = $('form[name="' + target + '-nav"] input[name="offset"]').val();

        if (after) {
            after.call();
        }else if($('.' + target + '-list').data('afterFilter')){
            window[$('.' + target + '-list').data('afterFilter')]($('.' + target + '-list').data('afterFilterParams'));
        }

        $(document).off('scroll', ScrollHandler);
        if($('.' + target + '-list').hasClass('infinite-scroll') &&  offset != 0 && num_items == offset) {
            $(document).on('scroll', {target: target}, ScrollHandler);


            //$('.loading-more').remove();
            //$('.' + target + '-list').append('<div class="loading-more">Scroll to load more<br><i class="fa fa-arrow-down"></i></div>');
            //$('.loading-more').fadeIn('fast');
            //setTimeout(function(){
            //    $('.loading-more').fadeOut('slow',function(){
            //        $(this).remove();
            //    });
            //},5000);

        }

        if(num_items == offset){
            $('.load-more[data-target="'+target+'"]').show();
            if($('.' + target + '-list').height() < $(window).height() - $('header').height()){
                more(target);
            }
        }else{
            $('.load-more[data-target="'+target+'"]').hide();
        }



    }});

    resize();
}

function registerGAEvent(title,content){
    dataLayer.push({
        'content': content,
        'event': title
    });
};
