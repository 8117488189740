/**
 * Created by 2YD on 10/16/17.
 */
function initDownloadsSubscribeForm(){

    $(document).on('click','.morphsearch  .download-row ',function($e){
        $('.morphsearch-close').trigger('click');
        $e.preventDefault();
    });
    $(document).on('click','.download-row',function($e){
        var c = getCookie('downloadSubscription');
		var t = $(this).data('target');
		
        if(c === null && t !=='_blank' ){
            modalPopup($(this).data('href'),$(this).data('title'));
        }else{
            //registerGAEvent('download_started',$(this).data('title'));
            window.open($(this).data('href'));
        }
        $e.preventDefault();
    });

    function modalPopup(downloadFileURL,downloadFileTitle){
        $('#downloadsSubscribe').modal('show');
        $('#downloadsSubscribe form').validate({
            submitHandler: function() {
                var data = $('#downloadsSubscribe form').serialize();
                var object = {
                    'success': function (response){

                        registerGAEvent('download_started',downloadFileTitle);
                       

                        setCookie('downloadSubscription',1,30 * 6);
						 window.open(downloadFileURL);
                        //$('.download-row[data-href="'+downloadFileURL+'"]').trigger('click');

                        $('#downloadsSubscribe').modal('hide');
                        $('#downloadsSubscribe input[name="email"]').val('');


                    },
                    'data' : data
                };
                $.ajax(object);
                return false;
            }
        });
    }
}

function initPopupSubscribeForm(){

    $('#popupSubscribe form').validate({
        submitHandler: function() {
            var data = $('#popupSubscribe form').serialize();
            var object = {
                'success': function (response){
                    if(response.status == 'ok'){
                        $('#popupSubscribe').modal('hide');
                        $('#popupSubscribe input[name="email"]').val('');
                    }else{
                        alert(response.error);
                    }

                },
                'data' : data
            };
            $.ajax(object);
            return false;
        }
    });
}


function initNewsletterForm() {
   $('#inlineNewsletterForm .submit-button').on('click', function () {
        if($('#newsletter_unsubscribe').prop('checked')==true){
            $('#newsletter_gdpr_disclaimer').removeAttr("required");
        }else{
            $('#newsletter_gdpr_disclaimer').attr( "required",'' );
        }
    });
    $('#inlineNewsletterForm').validate({
        rules: {
            'email': {
                required: true,
                email: true
            },
            
        },
        messages: {
            'email': 'Required'
        },
        focusCleanup: true,
        submitHandler: function() {
            var data = $('#inlineNewsletterForm').serialize();
            var object = {
                'success': function (response){
                    console.log(response);
                    if(response['status']=='ok'){
                       alert(response['message']);
                       $('#newsletter_unsubscribe').prop('checked',false);
                       $('#newsletter_gdpr_disclaimer').prop('checked',false);
                    }else{
                        alert(response['message']);
                    }
                    $('#inlineNewsletterForm input[name="email"]').val('');
                },
                'data' : data
            };
            $.ajax(object);
            return false;
        }, errorPlacement: function(error, element) {
            error.hide();
        },
        highlight: function (element, errorClass, validClass) {
           if($(element).is(':checkbox')){
                $(element).parent().find('.form-control-feedback').remove();
                $(element).parent().addClass('has-error has-feedback');
           }else{
                $(element).find('.form-control-feedback').remove();
                $(element).addClass('has-error has-feedback');
           }
            
        },
        unhighlight: function (element, errorClass, validClass) {
            if($(element).is(':checkbox')){
                $(element).parent().find('.form-control-feedback').remove();
                 $(element).parent().removeClass('has-error has-feedback');    
            }else{
                $(element).find('.form-control-feedback').remove();
                $(element).removeClass('has-error has-feedback'); 
            }
            
        }
    });
}