function initRoofshieldMap(){
    var resizeTime = 100; // total duration of the resize effect, 0 is instant
    var resizeDelay = 100; // time to wait before checking the window size again
    setTimeout(function(){
        $('#fig').mapster({
            clickNavigate: true,
            fillColor: 'ffffff',
            fillOpacity: 0.5
        });
    },500);

    function resize(maxWidth, maxHeight) {
        var image = $('#fig'),
            imgWidth = image.width(),
            imgHeight = image.height(),
            newWidth = 0,
            newHeight = 0;

        if (imgWidth / maxWidth > imgHeight / maxHeight) {
            newWidth = maxWidth;
        } else {
            newHeight = maxHeight;
        }
        image.mapster('resize', newWidth, newHeight, resizeTime);
    }

    // Track window resizing events, but only actually call the map resize when the
    // window isn't being resized any more
    function onWindowResize() {
        var curWidth = $(".complete-envelope-solution-image").width(),
            curHeight = $(".complete-envelope-solution-image").height(),
            checking = false;
        if (checking) {
            return;
        }
        checking = true;
        window.setTimeout(function() {
            var newWidth = $(".complete-envelope-solution-image").width(),
                newHeight = $(".complete-envelope-solution-image").height();
            if (newWidth === curWidth && newHeight === curHeight) {
                resize(newWidth, newHeight);
            }
            checking = false;
        }, resizeDelay);
    }
    $(window).bind('resize', onWindowResize);
    $(document).ready(function() {
        onWindowResize();
    });
}
