/***************************************************************************************************************************************/
$.cachedScript = function(url, options) {
	options = $.extend(options || {}, {
    	dataType: "script",
    	cache: true,
    	url: url
  	});
  	return jQuery.ajax(options);
};

var Cookie = {
	initCookies:function($target,cookie){

		var $google = $('input.checkbox-toggle-input.checkbox-toggle-input-google');
		var $marketing = $('input.checkbox-toggle-input.checkbox-toggle-input-marketing');
		
		if(getCookie('google') === null){
			$google.prop('checked',false);
			$google.removeClass('checked');		
			$('#ccc').addClass('open');
			$("body").addClass('cookie-overlay-showing');
		}
		
		if(getCookie('google') == 'true'){											
			$google.prop('checked',true);
			$google.addClass('checked');			
		}else{				
			$google.prop('checked',false);
			$google.removeClass('checked');			
		}

		if(getCookie('marketing')=='true'){				
			$marketing.prop('checked',true);
			$marketing.addClass('checked');
		}else{
			$marketing.prop('checked',false);
			$marketing.removeClass('checked');
		}

	},

	toggleCookies:function($target,cookie){
		var $google = $('input.checkbox-toggle-input.checkbox-toggle-input-google');
		var $marketing = $('input.checkbox-toggle-input.checkbox-toggle-input-marketing');
		setCookie(cookie,$target.is(':checked'),365);		
		if(getCookie('google') == 'true'){															
			$google.addClass('checked');
			$('#ccc-optional-categories .ccc-alert').removeClass('visible');
			
		}else{								
			$google.removeClass('checked');
			$('#ccc-optional-categories .ccc-alert').addClass('visible');
			
		}		

		if(getCookie('marketing')=='true'){								
			$marketing.addClass('checked');

		}else{
			$marketing.removeClass('checked');
		}


	},

	recomendedCookies :function(){
		var $google = $('input.checkbox-toggle-input.checkbox-toggle-input-google');			
		setCookie('google',true,365);		
		$google.prop('checked',true);
		$google.addClass('checked');						
		setTimeout(function(){
			location.reload();
		},500);	
	}		
};

/*
function filter(target){
	$('form[name="'+target+'-nav"] input[name="page"]').val(0);
	itemsOut();
	 setTimeout(function(){
		$.ajax({data:$('form[name="'+target+'-nav"]').serialize(),dataType:'html',success:function(data){
			var classes = $('#'+target+'-grid > ul').attr('class');
			$('#'+target+'-grid').replaceWith(data);
			$('#'+target+'-grid > ul').attr('class',classes);
			itemsIn();
		}});
	 },200);
}*/

function filter_list(target){
	$('form[name="'+target+'-nav"] input[name="page"]').val(0);
	$.ajax({data:$('form[name="'+target+'-nav"]').serialize(),dataType:'html',success:function(data){
		$('#'+target+'-list').fadeOut('fast',function(){
			$(this).html(data).fadeIn('fast');
		})
	}});
}
function itemsOut(){
	$('.grid_item').each(function(){
		var newX = randomMinToMax(-300, 300);
		var newY = randomMinToMax(-300, 300);
		$(this).css({
			'-webkit-transition' : 'all 0.5s',
			'opacity' : '0'
		})
	});
}
function itemsIn(){
	$('.grid_item').each(function(){
		var newX = randomMinToMax(-300, 300);
		var newY = randomMinToMax(-300, 300);
		$(this).css({
			'-moz-transform' : 'translate('+ newX +'px,'+ newY +'px)',
			'-webkit-transform' : 'translate('+  newX +'px,'+ newY +'px)',
			'opacity' : '0'
		})
	});
	setTimeout(function(){
		$('.grid_item').each(function(){
			var newX = 0;
			var newY = 0;
			$(this).css({
				'-moz-transform' : 'translate('+ newX +'px,'+ newY +'px)',
				'-moz-transition' : 'all 0.5s',
				'-webkit-transform' : 'translate('+  newX +'px,'+ newY +'px)',
				'-webkit-transition' : 'all 0.5s',
				'opacity' : '1'
			})
		})
	},200);
}

function randomMinToMax(min, max_v) {
	var range = max_v - min + 1;
	return Math.floor(Math.random()*range + min);
}

/*	
function more(t){
	var target = t.attr('rel');
	t.addClass('loading disabled');
	var next_page = Number($('form[name="'+target+'-nav"] input[name="page"]').val()) + 1;
	$('form[name="'+target+'-nav"] input[name="page"]').val(next_page);
	$.ajax({data:$('form[name="'+target+'-nav"]').serialize(),dataType:'html',success:function(data){
		t.removeClass('loading disabled');
		$('#'+target+'-list').append(data);
	}});
}
*/
function changeTab(t){
	var p = t.parent(),
	at = p.find('.mm_tab_active'),
	ac = at.find('.tab_content_wrapper'),
	c = t.find('.tab_content_wrapper');
	if (t.hasClass('mm_tab_active')){
	
	} else {
		ac.fadeOut('slow');
		c.fadeIn('slow');
		at.removeClass('mm_tab_active');
		t.addClass('mm_tab_active');
	}
}

function onModalOpen(){
	setTimeout(function(){
		
		var hash = $('#modal .meta').data('hash'),
		title = $('#modal .meta').data('title'),
		image = $('#modal .meta').data('image'),
		url = encodeURIComponent($('#modal .meta').data('url')),
		print_url = $('#modal .meta').data('printUrl'),
		description = $('#modal .meta').data('description');

		var bread = $('.container .breadcrumb').html();
		var s = '<a class="print-trigger" href="'+print_url+'" target="_blank" style="text-decoration:none;"><i class="icon-print" style="font-size:20px"></i></a>';
		
		if(hash){
			window.location.hash = hash;
		}
	
		$('.social-media-links').html(s);
		$('.modal-body .breadcrumb').html(bread);
		$('.modal-body .breadcrumb li:eq(0)').remove();
		$('.modal-body .breadcrumb li:eq(0)').remove();
		$('.modal-body .breadcrumb li').removeClass('active');
		$('.modal-body .breadcrumb li.last-child').remove();	
	},500);
	
}
function onModalClose(){
	$(this).removeData('modal');
	window.location.hash = '';
	$('#modal .meta').empty();
	$('#modal .modal-body').empty();
}

function parseHashUrl(url){
	var element = url.replace('/', '').split(':');
	if(element.length == 2 && element[0]!='products'){
		return (element.length == 2)?URL+'ajax/' + element[0] + ':popup/' + element[1]:'';
	}else if(element.length == 2 && element[0]=='products'){
		return (element.length == 2)?URL+'ajax/' + element[0] + ':popup/' + element[1]:'';
	}else{
		return '';
	}
}
function initSearchForm(){
	$('#search_form').validate({
		rules: {'search-param':'required'},
		messages:{'search-param':''},
		submitHandler: function() {
			$.ajax({data:$('#search_form').serialize(),dataType:'html',success:function(data){
				$('#search-results .modal-body .results').html(data);
				$('#search-results .modal-body .results ul').addClass('unstyled search-results-list');
				$('#search-results .modal-body .results ul li:even').addClass('even');
            	$('#search-results .modal-body .results ul li:odd').addClass('odd');
				$('#search-results').modal({
				  show: true
				})
			}});
		}
	});
	
	$("#submit_search").click(function(){
		$('#search_form').submit();
		return false;
	});
}

function initNewsletterForm(){
    $('form[name="join_newsletter"]').validate({
        errorPlacement: function(error, element) {
            error.hide();
        },
        submitHandler: function() {
            $.ajax({data:$('form[name="join_newsletter"]').serialize(),success:function(data){
                if(data.status == "ok"){
                    $('form[name="join_newsletter"] input:text').val("");

                    alert(data.message);
                }else if(data.status == 'error') {
                    alert(data.error);
                }else{
                    alert('An unknown error occurred. Please try again later');
                }
            }});
        }
    });
}


function equalizeHeight(target){
	$(target).each(function(){
		$(target).addClass('autoHeight');
		$(target).css('min-height','auto');
		if($(target).parent().hasClass('card')){
			$(target).css('height','auto');
		}
	});
	
    var min_height = 0;
    setTimeout(function(){
        $(target).each(function(){
            if($(this).innerHeight() > min_height){
                min_height = $(this).innerHeight();
            }
        });
        if(min_height>0){
            $( target).css('min-height',min_height);
			if($(target).parent().hasClass('card')){
				$(target).css('height',min_height);
			}
        }
		$(target).each(function(){			 
			$(target).removeClass('autoHeight');
		});
		
    },500);
	
}

function registerGAEvent(title,content){
    if(typeof(_gaq)!=='undefined'){
        _gaq.push(['_trackEvent', title, content ]);
    }
}



function setCookie(name, value , days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        expires = "; expires="+date.toGMTString();
    }
    document.cookie = name+"="+value+expires+"; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length,c.length);
        }
    }
    return null;
}

function eraseCookie(name) {
    setCookie(name,"",-1);
}