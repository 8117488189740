function initSubmitCV(){

    var uploader = new plupload.Uploader({
        runtimes : 'html5,flash,silverlight,html4',

        browse_button : 'pickfiles',
        container: document.getElementById('upload-container'),

        url : "/?module=medialibrary&action=upload",

        filters : {
            max_file_size : '2500kb',
            mime_types: [
                {title : "Documents", extensions : "pdf,doc,docx,txt,rtf"}
            ]
        },

        multi_selection:false,
        unique_names:true,
        init: {
            PostInit: function() {
                document.getElementById('filelist').innerHTML = '';
            },

            FilesAdded: function(up, files) {

                plupload.each(files, function(file) {
                    document.getElementById('filelist').innerHTML = '<div id="' + file.id + '">' + file.name + ' (' + plupload.formatSize(file.size) + ') <b></b></div>';
                });
                uploader.start();
            },

            UploadProgress: function(up, file) {
                document.getElementById(file.id).getElementsByTagName('b')[0].innerHTML = '<span>' + file.percent + "%</span>";
            },
            FileUploaded: function(up, file) {
                document.getElementById(file.id).getElementsByTagName('b')[0].innerHTML += '<input type="hidden" name="cv_file" value="' + file.target_name + '"/>';
            },
            Error: function(up, err) {
                document.getElementById('filelist').innerHTML = "\nError #" + err.code + ": " + err.message;
            }
        }
    });

    uploader.init();

    $('form[name="submit-cv-form"]').validate({
        submitHandler: function() {
            var data =  $('form[name="submit-cv-form"]').serialize();
            var object = {
                'success': function (response){
                    if(response['status']=='ok'){
                        $('form[name="submit-cv-form"]').closest('.modal').modal('hide');
                        alert(response['message'])
                    }else{
                        alert(response['error']);
                    }
                },
                'data' : data
            };
            $.ajax(object);
            return false;
        }
    });
}


